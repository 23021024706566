<template>
  <div class="page edit-page">
    <div class="top-wrapper">
      <div class="title"> {{ 85 > scrollTop ? title : ''}} </div>
      <div class="actions">
        <el-button type="primary" plain size="small" class="cancel" @click="goBack"> 取消 </el-button>
        <el-button type="primary" size="small" class="submit" @click="submit"> 保存 </el-button>
        <el-button v-if="id && publishFlag == 0 && perms.includes('video:publish')" type="primary" size="small" class="submit" @click="release"> 发布 </el-button>
        <el-button v-if="id && publishFlag == 1 && perms.includes('video:cancel')" type="primary" size="small" class="submit" @click="releaseCancel"> 取消发布 </el-button>
      </div>
    </div>

    <div class="content-wrapper">
      <el-row :gutter="20" type="flex" class="content-row">
        <el-col :lg="15" :md="15" :sm="15" :xs="24">
          <div class="form-container pa20 pb0">
            <el-form ref="form" :model="form" :rules="rules">
              <el-form-item label="涉及机型" prop="deviceIds">
                <el-cascader
                  ref="cascader"
                  :options="machineOptions"
                  :props="machineProps"
                  :show-all-levels="false"
                  v-model="deviceIdList"
                  filterable
                  clearable
                  placeholder="请选择机型"
                  :popper-class="proClass"
                  class="full-width font14"
                  @visible-change="proCascaderVisableChange"
                ></el-cascader>
              </el-form-item>

              <el-form-item label="主题" prop="title">
                <el-input
                  v-model="form.title"
                  maxlength="50"
                  placeholder="请输入主题"
                  class="full-width"
                  @blur="form.title = form.title.trim()"
                ></el-input>
              </el-form-item>

              <el-form-item label="上传视频" prop="videoLink">
                <!-- <div v-if="videoLink" class="video-wrapper">
                  <VideoPlayer :videoLink="videoLink"></VideoPlayer>
                </div>
                <div v-else class="upload-wrapper"> -->
                  <UploadVideo
                    ref="upload"
                    :files.sync="uploadNormalFiles"
                    :failedFiles.sync="uploadFailedFiles"
                    :limit="100"
                    :disabled="!canUpload"
                    message="支持扩展名: .mp4 .wmv .avi .m4v .mov"
                    class="full-width"
                    @onSuccess="handleUploadSuccess"
                  ></UploadVideo>
                <!-- </div> -->
              </el-form-item>

              <el-form-item label="视频封面" prop="videoImg">
                <el-button type="primary" size="mini" @click="downloadCoverTemplate(0)">工程机 生产型设备</el-button>
                <el-button type="primary" size="mini" @click="downloadCoverTemplate(1)">投影仪 IWB 其他</el-button>
                <el-button type="primary" size="mini" @click="downloadCoverTemplate(2)">数码复合机 打印机 印刷机</el-button>
                <el-button type="primary" size="mini" @click="downloadCoverTemplate(3)">文档解决方案 IT产品解决方案 Connectivity</el-button>
                <el-upload
                  class="img-upload"
                  accept=".png,.jpg,.jpeg"
                  :action="action"
                  :headers="headers"
                  :multiple="false"
                  :show-file-list="false"
                  :before-upload="handleBeforeUpload"
                  :on-success="handleImageUploaded"
                >
                  <div class="img-wrapper" :class="coverImageUrl ? 'have-img' : ''">
                    <img v-if="coverImageUrl" :src="coverImageUrl" class="avatar">
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                  </div>
                </el-upload>
              </el-form-item>
            </el-form>
          </div>
        </el-col>

        <el-col :lg="9" :md="9" :sm="9" :xs="24">
          <div class="upload-container pa20 pb0">
            <div class="title"> 视频 </div>

            <div v-if="uploadFiles.length > 0">
              <template v-for="(file, index) in uploadFiles">
                <Progress
                  :key="index"
                  :file="file"
                  :percentage="file.percentage"
                  showFileDownload
                  @retry="uploadRetry(file)"
                  @remove="uploadRemove(file)"
                ></Progress>
              </template>
            </div>
            <div v-else class="empty">
              <img :src="assets.emptyImg" class="empty-img" />
              <div class="empty-description"> 暂无视频~ </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import UploadVideo from '@/components/UploadVideo'
import VideoPlayer from '@/components/VideoPlayer'
import kong from '@/assets/images/kong.png'
import Progress from '@/components/Progress'
import { debounce } from '@/utils/debounce'
import shangchuan from '@/assets/images/shangchuan.png'

const defaultSettings = require('@/settings.js')
const url = defaultSettings.hostName

export default {
  components: {
    UploadVideo,
    Progress,
    VideoPlayer
  },
  data() {
    return {
      // 权限
      perms: JSON.parse(window.sessionStorage.getItem('perms')),
      // 视频ID
      id: '',
      // limit: 100,
      cached: {
        searchKey: '',
        deviceTypeId: '',
        deviceNameId: '',
        sortType: 1,
        publishFlag: '',
      },
      form: {
        title: '',
        videoImg: '',
        videoLink: '',
        videoDuration: '',
        videoSize: '',
        videoName: '',
        deviceIds: [],
        deviceNames: '',
      },
      deviceIdList: [],
      // 是否发布
      publishFlag: 0,
      // 机型选项
      machineProps: {
        multiple: true,
        emitPath: false,
        value: 'id',
        label: 'name',
        children: 'children'
      },
      // 机种机型数据
      machineOptions: [],
      // 对应el-upload组件的数据
      // 当重新上传时，会清空组件的文件列表数据
      // 正常的上传状态文件列表
      uploadNormalFiles: [],
      // 失败的上传文件列表
      uploadFailedFiles: [],
      // 静态文件
      assets: {
        emptyImg: kong,
        upload: shangchuan
      },
      rules: {
        title: [{
          required: true,
          message: '请输入主题',
          trigger: 'change'
        }],
        deviceIds: [{
          required: true,
          message: '请选择涉及机型',
          trigger: 'change'
        }],
        videoLink: [{
          required: true,
          message: '请上传视频',
          trigger: 'change'
        }],
        videoImg: [{
          required: true,
          message: '请上传封面',
          trigger: 'change'
        }],
      },
      // 吸顶标题隐藏
      scrollTop: 0,
      templateLinks: [
        // 工程机 生产型设备
        'https://ricoh-oss.oss-cn-shenzhen.aliyuncs.com/ricoh/template/%E5%B7%A5%E7%A8%8B%E6%9C%BA%20%E7%94%9F%E4%BA%A7%E5%9E%8B%E8%AE%BE%E5%A4%87.pptx',
        // 投影仪 IWB 其他
        'https://ricoh-oss.oss-cn-shenzhen.aliyuncs.com/ricoh/template/%E6%8A%95%E5%BD%B1%E4%BB%AA%20IWB%20%E5%85%B6%E4%BB%96.pptx',
        // 数码复合机 打印机 印刷机
        'https://ricoh-oss.oss-cn-shenzhen.aliyuncs.com/ricoh/template/%E6%95%B0%E7%A0%81%E5%A4%8D%E5%90%88%E6%9C%BA%20%E6%89%93%E5%8D%B0%E6%9C%BA%20%E5%8D%B0%E5%88%B7%E6%9C%BA.pptx',
        // 文档解决方案 IT产品解决方案 Connectivity
        'https://ricoh-oss.oss-cn-shenzhen.aliyuncs.com/ricoh/template/%E6%96%87%E6%A1%A3%E8%A7%A3%E5%86%B3%E6%96%B9%E6%A1%88%20IT%E4%BA%A7%E5%93%81%E8%A7%A3%E5%86%B3%E6%96%B9%E6%A1%88%20Connectivity.pptx',
      ],
    }
  },
  computed: {
    title() {
      if (this.id) return '修改视频'
      return '新增视频'
    },
    proClass() {
      if (this.proVisible) return 'hide-cascader-scroll'
      return 'hide-cascader-scroll-reset'
    },
    action() {
      return url + "bsi/sysAttachFile/addFile"
    },
    headers() {
      const token = sessionStorage.getItem('token') || ''

      return {
        token: token
      }
    },
    canUpload() {
      return this.uploadNormalFiles.length === 0 && this.uploadFailedFiles.length === 0
    },
    // 所有状态的上传文件
    uploadFiles() {
      let files = []

      if (this.uploadNormalFiles) files.push.apply(files, this.uploadNormalFiles)
      if (this.uploadFailedFiles) files.push.apply(files, this.uploadFailedFiles)
      return files
    },
    // 视频链接
    videoLink() {
      return this.form.videoLink
    },
    // 封面图
    coverImageUrl() {
      return this.form.videoImg
    },
  },
  watch: {
    deviceIdList(val) {
      this.syncFormValue()
    },
    machineOptions(val) {
      this.$nextTick(() => {
        this.syncFormValue()
      })
    }
  },
  created() {
    this.id = this.$route.query.id || ''

    this.cached.searchKey = this.$route.params.searchKey || ''
    this.cached.deviceTypeId = this.$route.params.deviceTypeId || ''
    this.cached.deviceNameId = this.$route.params.deviceNameId || ''
    this.cached.sortType = this.$route.params.sortType || 1
    this.cached.publishFlag = this.$route.params.publishFlag === 0 ? 0 : ''

    this.getMachineOptions()
    this.getDetail()
  },
  mounted() {
    window.addEventListener('scroll',this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    // 获取视频详情
    getDetail() {
      if (!this.id) return

      let that = this
      that.$request.post(
        "dataVideoInfo",
        true, {
          id: that.id
        },
        function (r) {
          if (r.code == "0") {
            that.form.title = r.data.title
            that.form.videoImg = r.data.videoImg
            that.form.videoLink = r.data.videoLink
            that.form.videoDuration = r.data.videoDuration
            that.form.deviceIds = r.data.deviceIds
            that.form.deviceNames = r.data.deviceNames
            that.form.videoName = r.data.videoName
            that.form.videoSize = r.data.videoSize
            that.publishFlag = r.data.publishFlag

            that.$nextTick(() => {
              that.deviceIdList = r.data.deviceIds.split(',')
            })

            that.uploadNormalFiles = []
            const file = {
              fileName: r.data.videoName,
              fileAddr: r.data.videoLink,
              status: 'success',
              percentage: 100,
              fileSize: r.data.videoSize
            }
            
            that.uploadNormalFiles.push(file)
          } else {
            that.$message.error(r.msg)
          }
        }
      )
    },
    // 新增|修改视频
    submit: debounce(function () {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (this.id) {
            // 修改
            const data = Object.assign(this.form, {
              id: this.id
            })
            let that = this
            that.$request.post(
              "dataVideoUpdate",
              true,
              data,
              function (r) {
                if (r.code == "0") {
                  that.$message.success('保存成功')
                  that.$router.go(-1)
                } else {
                  that.$message.error(r.msg)
                }
              }
            )
          } else {
            // 新增
            let that = this
            that.$request.post(
              "dataVideoAdd",
              true,
              that.form,
              function (r) {
                if (r.code == "0") {
                  that.$message.success('保存成功')
                  that.$router.go(-1)
                } else {
                  that.$message.error(r.msg)
                }
              }
            )
          }
        } else {
          return false
        }
      })
    }, 500),
    // 发布
    release: debounce(function() {
      if (!this.id) return

      let that = this

      that.$request.post(
        "dataVideoPublish",
        true,
        {
          id: this.id,
          publishFlag: 1
        },
        function (r) {
          if (r.code == "0") {
            that.$message.success('发布成功')
            that.$router.go(-1)
          } else {
            that.$message.error(r.msg)
          }
        }
      )
    }, 500),
    // 取消发布
    releaseCancel: debounce(function() {
      if (!this.id) return

      let that = this

      that.$request.post(
        "dataVideoPublish",
        true,
        {
          id: this.id,
          publishFlag: 0
        },
        function (r) {
          if (r.code == "0") {
            that.$message.success('取消发布成功')
            that.$router.go(-1)
          } else {
            that.$message.error(r.msg)
          }
        }
      )
    }, 500),
    // 获取所有机型数据
    getMachineOptions(all) {
      const getChildrens = (category) => {
        const children = []
        if (category.children && category.children.length > 0) {
          for (const child of category.children) {
            const c = getChildrens(child)
            children.push.apply(children, c)
          }
        } else {
          children.push(category.id)
        }

        return children
      }

      let contractTypeIds = ''
      if (all) {
        // 获取所有产品数据
      } else {
        // 根据签约类型获取产品数
        if (this.sendTargetSign) {
          contractTypeIds = this.sendTargetSign.join(',')
        }
      }

      let that = this
      that.$request.post(
        "sysModelDevice",
        false, {
          contractTypeIds: contractTypeIds
        },
        function (r) {
          if (r.code == "0") {
            that.machineRelations = []
            const machineOptions = r.data

            for (const category of machineOptions) {
              category.disabled = false
              const lastChildrens = getChildrens(category)
              const relation = [category.id, lastChildrens]
              that.machineRelations.push(relation)
            }

            that.machineOptions = machineOptions
          } else {
            that.$message.error(r.msg);
          }
        }
      )
    },
    goBack() {
      this.$router.push({
        name: 'video',
        params: this.cached
      })
    },
    // 级联选择器的选项同步至 form 表单中的数据
    syncFormValue() {
      const nodes = this.$refs.cascader.getCheckedNodes(true)
      console.log('nodes: ', nodes)
      const names = nodes.map(node => node.label)
      const ids = nodes.map(node => node.value)
      this.form.deviceIds = ids.join(',')
      this.form.deviceNames = names.join(',')
      console.log('form: ', this.form)
    },
    // 解决cascader组件margin-bottom: -17px 失效的问题
    proCascaderVisableChange(visible) {
      this.proVisible = visible
    },
    // 上传失败重试
    uploadRetry(file) {
      this.uploadFailedFiles.splice(this.uploadFailedFiles.indexOf(file), 1)
      file.status = 'ready'
      this.uploadNormalFiles.push(file)
      this.$refs.upload.submit()
    },
    // 取消上传
    uploadRemove(file) {
      console.log('删除视频文件: ', file)
      console.log('所有视频文件: ', this.uploadNormalFiles)
      // this.$refs.upload.abort(file)
      if (this.uploadNormalFiles.indexOf(file) > -1) {
        this.uploadNormalFiles.splice(this.uploadNormalFiles.indexOf(file), 1)
      }
      if (this.uploadFailedFiles.indexOf(file) > -1) {
        this.uploadFailedFiles.splice(this.uploadFailedFiles.indexOf(file), 1)
      }
      // this.uploadNormalFiles.splice(this.uploadNormalFiles.indexOf(file), 1)
      this.form.videoLink = ''
      this.form.videoDuration = ''
    },
    handleBeforeUpload(file) {
      const acceptList = ['image/png', 'image/jpg', 'image/jpeg']
      if (!acceptList.includes(file.type)) {
        this.$message.warning('文件格式错误')
        return false
      }

      const limitSize = file.size / 1024 / 1024 <= 200
      if (!limitSize) {
        this.$message.warning('文件大小不能超过200M')
        return false
      }
    },
    handleUploadSuccess(file, fileList) {
      console.log('上传成功: ', file)
      console.log('上传成功: ', fileList)

      // setTimeout(() => {
      //   this.form.videoLink = file.response.data.videoLink
      //   this.form.videoDuration = file.response.data.videoDuration
      // }, 10000)
      this.form.videoLink = file.response.data.videoLink
      this.form.videoDuration = file.response.data.videoDuration
      this.form.videoName = file.response.data.videoName
      this.form.videoSize = file.response.data.videoSize
    },
    // 上传封面成功
    handleImageUploaded(file, fileList) {
      this.form.videoImg = file.data.fileAddr
    },
    // 下载封面模板 打印机
    downloadCoverTemplate(index) {
      const url = this.templateLinks[index]

      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.download = ''
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    // 处理吸顶时title隐藏
    handleScroll() {
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    }
  }
}
</script>

<style lang="scss" scoped>
.img-upload {
  width: 100%;

  /deep/ .el-upload {
    position: relative;
    overflow: hidden;
    
    .img-wrapper {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      width: 178px;
      height: 178px;

      &.have-img {
        border: none;
        width: auto;

        .avatar, .avatar-uploader-icon {
          width: auto;
        }
      }
      &:hover {
        border-color: #409EFF;
      }
      .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
      }
      .avatar {
        width: 178px;
        // width: auto;
        height: 178px;
        display: block;
      }
    }
  }
}
</style>
